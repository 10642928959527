<template>
    <ion-page>
        <ThanhTieuDeVue>
            <template #before>
                <div class="row align-center justify-space-between">
                    <DxButton
                        type="default"
                        icon="mdi mdi-chevron-left"
                        class="ml-1"
                        @click="$router.go(-1)"
                    />
                    THÔNG TIN GỬI HÀNG
                    <DxButton
                        type="default"
                        icon="mdi mdi-chevron-left"
                        class="ml-1"
                        style="opacity: 0"
                    />
                </div>
            </template>
        </ThanhTieuDeVue>
        <ion-content :scroll-events="false">
            <div class="frame-content">
                <div class="frame-data">
                    <DxValidationGroup ref="formValidation">
                        <div class="row mb-2">
                            Đã trả cước
                            <DxSwitch
                                v-model:value="DaThanhToan"
                                class="ml-2"
                            />
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <div class="row font-12">
                                    Số điện thoại người nhận (*)
                                </div>
                                <DxTextBox
                                    v-model="model.SoDienThoaiNguoiNhan"
                                    validationMessageMode="always"
                                    mode="tel"
                                    :tabIndex="1"
                                    ref="SoDienThoaiNguoiNhan"
                                    styling-mode="underlined"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator ref="refValidateSoDienThoai">
                                        <DxRequiredRule
                                            message="Số điện thoại người nhận không được bỏ trống!"
                                        />
                                        <DxStringLengthRule
                                            :max="13"
                                            message="Số điện thoại không được vượt quá 13 ký tự!"
                                        />
                                        <DxNumericRule
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxRangeRule
                                            :min="0"
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxPatternRule
                                            :pattern="phonePattern"
                                            message="Vui lòng nhập đúng định dạng số điện thoại!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="xs12">
                                <div class="row font-12">Điểm trả hàng (*)</div>
                                <!-- :onEnterKey="onEnterKey" -->
                                <DxSelectBox
                                    v-model="model.DiemXuong"
                                    :items="DanhSachDiemXuong"
                                    display-expr="tenDiemXuong"
                                    value-expr="guidDiemXuong"
                                    ref="refTab2"
                                    :tabIndex="2"
                                    :search-enabled="true"
                                    styling-mode="underlined"
                                    no-data-text="Không có dữ liệu!"
                                    :onItemClick="onItemClickDiemXuong"
                                    validationMessageMode="always"
                                >
                                    <DxValidator ref="refValidateDiemXuong">
                                        <DxRequiredRule
                                            message="Điểm trả hàng không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxSelectBox>

                                <GoiYVue
                                    class="pt-3"
                                    :data="GoiYDiemXuong"
                                    :key="GoiYDiemXuong"
                                    @ChonGoiY="ChonGoiYDiemXuong"
                                />
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="xs12">
                                <div class="row font-12">Giá cước (*)</div>
                                <DxNumberBox
                                    v-model="model.GiaVe"
                                    :show-spin-buttons="false"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    format=",##0đ"
                                    ref="refTab3"
                                    :min="0"
                                    :tabIndex="3"
                                    :onKeyUp="onChangeGiaVe"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator ref="refValidateGiaVe">
                                        <DxRequiredRule
                                            message="Vui lòng nhập giá cước!"
                                        />
                                        <DxRangeRule
                                            :max="2147483647"
                                            message="Giá vé không được vượt quá 2,147,483,647!"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                                <GoiYVue
                                    class="pt-3"
                                    :data="GoiYGiaVe"
                                    :key="GoiYGiaVe"
                                    @ChonGoiY="chonGoiYGiaVe"
                                />
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="xs12">
                                <div class="row font-12">
                                    Số điện thoại người gửi
                                </div>
                                <DxTextBox
                                    v-model="model.SoDienThoaiNguoiGui"
                                    validationMessageMode="always"
                                    mode="tel"
                                    :tabIndex="1"
                                    ref="refTab1"
                                    styling-mode="underlined"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator ref="refValidateSoDienThoai">
                                        <DxStringLengthRule
                                            :max="13"
                                            message="Số điện thoại không được vượt quá 13 ký tự!"
                                        />
                                        <DxNumericRule
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxRangeRule
                                            :min="0"
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxPatternRule
                                            :pattern="phonePattern"
                                            message="Vui lòng nhập đúng định dạng số điện thoại!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="xs12">
                                <div class="row font-12">
                                    Thời gian giao hàng dự kiến
                                </div>
                                <DxDateBox
                                    v-model="model.ThoiGianGiaoHangDuKien"
                                    validationMessageMode="always"
                                    displayFormat="HH:mm dd/MM/yyyy"
                                    dropDownButtonTemplate="customIcon"
                                    type="datetime"
                                    :tabIndex="1"
                                    :useMaskBehavior="true"
                                    ref="refTab1"
                                    styling-mode="underlined"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                </DxDateBox>
                            </div>
                        </div>

                        <ViDienTuVue
                            :LayDanhSachVi="false"
                            @ChonVi="
                                (item) => {
                                    model.Vi = item;
                                }
                            "
                        />

                        <div
                            class="row justify-space-between align-center mb-2"
                        >
                            <div class="grow">
                                Ảnh: {{ ArrPreviewImg.length }}/3
                            </div>
                            <div class="shrink">
                                <div class="row align-center">
                                    <ion-checkbox
                                        v-model="CheckInVe"
                                    ></ion-checkbox>
                                    <span class="font-14 ml-2">In phiếu</span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <ListAnhChupVue
                                v-model:ArrPreviewImg="ArrPreviewImg"
                                v-model:ArrImgPush="ArrImgPush"
                            />
                        </div>
                    </DxValidationGroup>
                </div>

                <div class="frame-button" style="">
                    <div class="row justify-end align-center">
                        <ion-button
                            :color="
                                !model.Vi ||
                                !model.GiaVe ||
                                !model.DiemXuong ||
                                disabledXacNhan
                                    ? 'dark'
                                    : 'primary'
                            "
                            fill="solid"
                            expand="block"
                            @click="XacNhan()"
                            :disabled="
                                !model.Vi ||
                                !model.GiaVe ||
                                !model.DiemXuong ||
                                disabledXacNhan
                            "
                        >
                            Xác nhận
                        </ion-button>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonPage,
    onIonViewWillEnter,
    IonCheckbox,
    IonButton,
    onIonViewWillLeave,
} from "@ionic/vue";
import {
    DxButton,
    DxSelectBox,
    DxSwitch,
    DxTextBox,
    DxValidator,
    DxNumberBox,
    DxDateBox,
    DxValidationGroup,
} from "devextreme-vue/";
import {
    DxRequiredRule,
    DxRangeRule,
    DxStringLengthRule,
    DxNumericRule,
    DxPatternRule,
} from "devextreme-vue/data-grid";
import { mapActions, mapState } from "vuex";
import GoiYVue from "../../../components/GoiY.vue";
import ChuyenDi from "../../../../class/ChuyenDi";
import ThanhTieuDeVue from "../../../components/_Common/ThanhTieuDe.vue";
import ViDienTuVue from "../../../components/ViDienTu.vue";
import ListAnhChupVue from "../components/ListAnhChup.vue";
export default {
    components: {
        IonContent,
        IonPage,
        ThanhTieuDeVue,
        DxButton,
        DxSwitch,
        GoiYVue,
        DxTextBox,
        DxSelectBox,
        DxValidator,
        DxRequiredRule,
        DxRangeRule,
        DxStringLengthRule,
        DxNumericRule,
        DxPatternRule,
        DxNumberBox,
        DxDateBox,
        ListAnhChupVue,
        ViDienTuVue,
        DxValidationGroup,
        IonCheckbox,
        IonButton,
    },
    data() {
        return {
            DaThanhToan: false,
            CheckInVe: false,
            DanhSachDiemXuong: [],
            model: {
                SoDienThoaiNguoiGui: null,
                SoDienThoaiNguoiNhan: null,
                DiemXuong: null,
                GiaVe: null,
                SoDienThoaiNguoiGui: null,
                ThoiGianGiaoHangDuKien: new Date(),
                Vi: null,
            },
            location: null,
            GoiYDiemXuong: [],
            GoiYGiaVe: [],
            phonePattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
            ArrPreviewImg: [],
            ArrImgPush: [],
            disabledXacNhan: false,
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
    },
    watch: {
        "model.DiemXuong": {
            handler: async function (val) {
                if (val) {
                    let query = this.$route.query;
                    let rs = await new ChuyenDi().layGiaTienGoiYTheoDiemXuong(
                        query.guidChuyenDi,
                        "00000000-0000-0000-0000-000000000000",
                        val
                    );
                    if (rs.Data.status == true) {
                        this.model.GiaVe = rs.Data.data;
                        this.LayDsGiaTienGoiY(this.model.GiaVe);
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions("Ve", ["layDSDiemDungTheoLoTrinh"]),
        ...mapActions("HangHoa", [
            "NhanHangHoa",
            "CapNhatHinhAnhVanChuyenHang",
        ]),
        validate() {
            return this.$refs.formValidation.instance.validate();
        },
        onItemClickDiemXuong(item) {
            if (item.itemData) {
                this.model.DiemXuong = item.itemData.guidDiemXuong;
            }
        },
        ChonGoiYDiemXuong(item) {
            this.model.DiemXuong = item.guidDiemXuong;
        },
        chonGoiYGiaVe(item) {
            this.model.GiaVe = item.value;
        },
        onChangeGiaVe(e) {
            if (("" + e.component._parsedValue).length < 9) {
                let data = [],
                    giaGoiY = 0;
                let giaVeHienTai = e.component._parsedValue;
                if (!e.component._parsedValue) {
                    this.GoiYGiaVe = data;
                    return;
                }
                if (giaVeHienTai <= 5000) {
                    this.GoiYGiaVe = data;
                    return;
                }
                function goiYGia() {
                    let currency = [500000, 100000, 50000, 20000, 10000, 5000];
                    let i = 0;
                    let giaVe = 0;
                    let temp = 0;
                    while (true) {
                        if (i > 50 || temp > giaVeHienTai || !currency[i]) {
                            break; //Thoát khỏi white
                        }
                        if (giaVeHienTai > currency[i]) {
                            temp += currency[i];
                            if (temp > giaVeHienTai) {
                                //Tiếp tục thử với mệnh giá tiếp theo
                                temp = giaVe;
                                i++;
                            }
                        } else {
                            i++;
                        }
                        if (temp <= giaVeHienTai) {
                            //Chốt giá
                            giaVe = temp;
                        }
                    }
                    return giaVe;
                }
                giaGoiY = goiYGia();
                this.LayDsGiaTienGoiY(giaGoiY);
            } else {
                this.GoiYGiaVe = [];
            }
        },
        LayDsGiaTienGoiY(giaGoiY) {
            let data = [];
            let giaGoiYThap = giaGoiY;
            let giaGoiYCao = giaGoiY;
            for (let i = 0; i < 5; i++) {
                if (giaGoiYThap < 10000) {
                    break;
                }
                let item = {
                    id: i,
                    active: false,
                };
                if (i < 3) {
                    item.value = giaGoiYCao;
                    item.text = this.$MoneyFormat(giaGoiYCao) + "đ";
                    data.push(item);
                    giaGoiYCao = giaGoiYCao + 5000;
                } else {
                    giaGoiYThap = giaGoiYThap - 5000;
                    item.value = giaGoiYThap;
                    item.text = this.$MoneyFormat(giaGoiYThap) + "đ";
                    data.unshift(item);
                }
            }
            this.GoiYGiaVe = data;
        },
        async LayDanhSachDiemXuongTheoLoTrinh() {
            try {
                let rs = await this.layDSDiemDungTheoLoTrinh(
                    this.$route.query.guidLoTrinh
                );
                if (rs.StatusCode == 200) {
                    this.DanhSachDiemXuong = rs.Data.data;
                    if (this.DanhSachDiemXuong.length > 0) {
                        this.model.DiemXuong =
                            rs.Data.data[rs.Data.data.length - 1].guidDiemXuong;
                    }
                    this.GoiYDiemXuong = this.DanhSachDiemXuong.map((e) => {
                        e.text = e.tenDiemXuong;
                        e.id = e.guidDiemXuong;
                        return e;
                    });
                }
            } catch (error) {
                console.log(
                    "🚀 ~ file: ThongTinSoatVe.vue ~ line 280 ~ mounted ~ error",
                    error
                );
            }
        },
        async XacNhan() {
            this.disabledXacNhan = true;
            if (this.validate().isValid && this.model.Vi) {
                let query = this.$route.query;
                let DiemXuong =
                    this.DanhSachDiemXuong.filter(
                        (x) => x.guidDiemXuong == this.model.DiemXuong
                    )[0] || {};
                //set data thông tin xác nhận thanh toán
                let thongTinXacNhanThanhToan = {
                    idChuyenDi: query.guidChuyenDi,
                    tongTien: this.model.GiaVe,
                    soDienThoaiNhan: this.model.SoDienThoaiNguoiNhan,
                    soDienThoaiGui: this.model.SoDienThoaiNguoiGui || "",
                    idDiemNhan: DiemXuong.guidDiemXuong,
                    tenDiemNhan: DiemXuong.tenDiemXuong,
                    thoiGianGiaoHangDuKien: this.$Helper.getDateString(
                        this.model.ThoiGianGiaoHangDuKien
                    ),
                    daTraCuoc: this.DaThanhToan,
                    toaDoGuiHang: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                    danhSachHinhAnh: this.ArrImgPush,
                };
                // this.dialogXacNhanThanhToan = true;
                this.XacNhanHangHoa(thongTinXacNhanThanhToan);
            } else {
                this.disabledXacNhan = false;
                this.$Helper.autoFocusError(this.validate());
            }
        },
        async XacNhanHangHoa(thongTinXacNhanThanhToan) {
            let toast = {
                type: "error",
                visible: true,
            };
            try {
                this.$startLoading;
                let rs = await this.NhanHangHoa(thongTinXacNhanThanhToan);
                this.disabledXacNhan = false;

                if (rs.Data.status == true) {
                    toast.message = "Lấy hàng thành công!";
                    toast.type = "success";
                    this.emitter.emit("onToast", toast);
                    // if (this.ArrImgPush.length > 0) {
                    //     this.CapNhatHinhAnhHangHoa(rs.Data.data);
                    // }
                    if (this.CheckInVe == true) {
                        this.layThongTinVe(rs.Data.data);
                        return;
                    }
                    this.resetData();
                    this.$router.push({
                        path: "/tabs/HangHoa",
                    });
                } else {
                    toast.message = rs.Data.message;
                    this.emitter.emit("onToast", toast);
                }
                this.$stopLoading;
            } catch (error) {
                this.disabledXacNhan = false;
                this.$stopLoading;
                if (error.StatusMessage) {
                    toast.message = error.StatusMessage;
                } else {
                    toast.message = error;
                }
                this.emitter.emit("onToast", toast);
            }
        },
        // async CapNhatHinhAnhHangHoa(idNhatKy) {
        //     try {
        //         var form = new FormData();
        //         form.append("idNhatKy", idNhatKy);
        //         form.append("isGuiHang", false);

        //         this.ArrImgPush.forEach((x) => {
        //             form.append("DanhSachHinhAnh", x);
        //         });
        //         await this.CapNhatHinhAnhVanChuyenHang(form);
        //         this.$stopLoading;
        //     } catch (error) {
        //         this.$stopLoading;
        //         console.error(error);
        //     }
        // },
        async layThongTinVe(idNhatKy) {
            let toast = {
                type: "error",
                visible: true,
            };
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVeTheoIdNhatKyGuiHang"),
                    "IdNhatKy",
                    idNhatKy
                );
                if (rs == "error") {
                    setTimeout(() => {
                        toast.message = "In thất bại!";
                        this.emitter.emit("onToast", toast);
                    }, 4000);
                }
                this.$router.push({
                    path: "/tabs/HangHoa",
                });
                this.$stopLoading;
            } catch (error) {
                console.error(error);
                this.$router.push({
                    path: "/tabs/HangHoa",
                });
                this.$stopLoading;
            }
        },
        // getLocation() {
        //     let self = this;
        //     return new Promise((resolve, reject) => {
        //         navigator.geolocation.getCurrentPosition(
        //             ({ coords }) => {
        //                 self.location = {
        //                     latitude: coords.latitude,
        //                     longitude: coords.longitude,
        //                 };
        //                 resolve(self.location);
        //             },
        //             (err) => {
        //                 console.log("🚀 ~  err", err);
        //                 resolve("null");
        //             },
        //             { timeout: 3000, enableHighAccuracy: true }
        //         );
        //     });
        // },
        ThemAnh(ev) {
            let toast = {
                type: "error",
                visible: true,
            };
            var self = this;
            var files = Array.from(ev.target.files || []);
            var fileInput = document.getElementById("input-file");

            var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            var check = false;
            files.forEach((e) => {
                if (!allowedExtensions.exec(e.name)) {
                    return (check = true);
                }
            });
            if (check == true) {
                toast.message = "Chỉ chọn tệp có định dạng: .jpeg/.jpg/.png";
                this.emitter.emit("onToast", toast);
                fileInput.value = "";
                return false;
            } else {
                if (this.ArrPreviewImg.length + files.length < 4) {
                    files.forEach(function (file) {
                        // Kiểm tra nhỏ hơn 4MB
                        if (file.size < 4 * 1024 * 1024) {
                            var reader = new FileReader();
                            reader.onload = function () {
                                if (FileReader.DONE == this.readyState) {
                                    var content = this.result;
                                    var blob = new Blob([content], {
                                        type: file.type,
                                    });
                                    self.ArrPreviewImg.push(
                                        URL.createObjectURL(blob)
                                    );
                                }
                            };
                            var a = reader.readAsArrayBuffer(file);
                            self.ArrImgPush.push(file);
                        } else {
                            alert("Vui lòng chỉ chọn ảnh nhỏ hơn 4MB!");
                        }
                    });
                } else {
                    toast.message = "Vui lòng chọn tối đa 3 ành";
                    this.emitter.emit("onToast", toast);
                    fileInput.value = "";
                }
            }
        },
        resetData() {
            this.model = {
                SoDienThoaiNguoiGui: null,
                SoDienThoaiNguoiNhan: null,
                DiemXuong: null,
                GiaVe: null,
                SoDienThoaiNguoiGui: null,
                ThoiGianGiaoHangDuKien: new Date(),
                Vi: null,
            };
            this.ArrPreviewImg = [];
            this.ArrImgPush = [];
            this.$refs.formValidation.instance.reset();
        },
    },
    created() {
        onIonViewWillEnter(() => {
            setTimeout(() => {
                this.emitter.emit("LayDanhSachVi");
                this.$refs.SoDienThoaiNguoiNhan.instance.focus();
                this.LayDanhSachDiemXuongTheoLoTrinh();
            }, 500);
        });
    },
    mounted() {
        onIonViewWillLeave(() => {
            this.resetData();
        });
    },
};
</script>

<style scoped>
.frame-content {
    padding: 16px;
    position: relative;
}
>>> .dx-texteditor.dx-editor-underlined .dx-texteditor-input {
    padding: 2px 0 6px !important;
}
>>> .dx-placeholder {
    top: -2px !important;
}
.frame-data {
    padding-bottom: 56px;
}
.frame-button {
    border-top: 1px solid rgb(218, 220, 224);
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 9;
    left: 0;
    padding: 4px 8px;
}
</style>